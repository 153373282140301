import Button from '../../UI/Button/Button.js';
import DynamicFilterDropdown from '../Common/DynamicFilters/FilterDropdown.js';
import Download from '../../../assets/svg/Download.js';
import {
  getValues,
} from '../Common/DynamicFilters/FilterStateBuilder.ts';
import classes from '../List.module.scss';
import DynamicFilterInputs from '../Common/DynamicFilters/FilterInputs.js';
import { downloadCSVFromFetch } from '../../../api/fetch.ts';
import Select from '../../UI/Select/Select.js';
import { useState } from 'react';
import { useNotification } from '../../../hooks/NotificationContext.tsx';

function ReportSearchForm({ onSearch, filters, fetchRequest, fileName, params, button_text, bulkActions, onBulkAction, disclaimer, onSubmit, downloadNotif }) {
  const {
    filterState: [filterState, setFilter],
    filterValues,
    resets,
  } = filters;

  const [bulkAction, setBulkAction] = useState('');
  const { addNotification } = useNotification()
  const setFilterState = (filter) => {
    setFilter({ ...filter });
  };

  function submitHandler(event) {
    if (event) {
      event.preventDefault();
      event.target.reset();
    }

    onSubmit && onSubmit()


    // console.log('submitting...')

    const valueResponse = getValues(filterState, filterValues);
    // console.log(valueResponse)

    if (valueResponse.wasValid) {
      // console.log('searching')
      onSearch(valueResponse.body);
    } else {
      valueResponse.invalid_entries.forEach(entry => {
        if (!filterValues[entry].inputBlurHandler) {
          return;
        }
        filterValues[entry].inputBlurHandler();
      })
    }
  }

  function runResets() {
    resets.forEach((reset) => reset());
  }

  function downloadHandler() {
    downloadCSVFromFetch(fetchRequest, params, {}, fileName);
    if(downloadNotif) {
      addNotification({
        text: downloadNotif,
        btnText: 'OK',
        color: '#333333'
      })
    }
    
  }

  // Object.keys(filterState).forEach((f) => console.log(f,filterState[f], 'is_nonarray', !Array.isArray((filterState[f])), 'is_nonremovable', !filterState[f].non_removable))
  // console.log('found', Object.keys(filterState).find((f) => !Array.isArray((filterState[f])) && !filterState[f].non_removable))
  return (
    <div>
      <div className={classes.Form__container}>
        <div className="container">
          
          <div className={classes.Form__text}>
            {filterState.length > 0 && (<>
            Enter <b>any</b> search parameter below and click search.
            <span onClick={runResets} className={classes.Form__clear}>
              RESET FILTERS
            </span></>)}
            {disclaimer}
            {Object.keys(filterState).find((f) => !Array.isArray((filterState[f])) && !filterState[f].non_removable) && 
            <DynamicFilterDropdown
              filterState={filterState}
              setFilterState={setFilterState}
            />}
          </div>
          <form onSubmit={submitHandler} className={classes.Form}>
            <DynamicFilterInputs
              filterState={filterState}
              setFilterState={setFilterState}
              resets={resets}
              filterValues={filterValues}
              submitHandler={submitHandler}
              predicate={(v) => v.non_removable}
            />
            <div className={classes.Form__col}>
              {button_text && (
              <Button color="primary" type="submit" clsType="small">
                {button_text}
              </Button>)}
            </div>
          </form>

          <DynamicFilterInputs
            filterState={filterState}
            setFilterState={setFilterState}
            resets={resets}
            filterValues={filterValues}
            submitHandler={submitHandler}
            predicate={(v) => !v.non_removable}
          />
        </div>
      </div>

      <div className="container">
        <div className={classes.Row}>
          {bulkActions &&
            <div style={{display: 'flex'}}>
              <Select
                outerDivClassName={classes.bulk_select}
                value={bulkAction}
                onChange={e => setBulkAction(e.target.value)}
                options={[{ label: 'Bulk Actions', value: '' }, ...bulkActions.map(action => { return { value: action, label: action } })]}
              />
              {bulkAction && (
                <Button className={`${classes.fill_width} ${classes.bulk_action}`} onClick={() => onBulkAction(bulkAction)}>Run Action</Button>
              )}

            </div>
          }
          <div className={classes.DownloadCol}>
            <div className={classes.Btn} onClick={downloadHandler}>
              Download <Download />
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default ReportSearchForm;

import React, { useState, createContext, useContext, useMemo } from "react";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion"
import classes from "./NotificationContext.module.scss"
import { Paper, Typography } from "@mui/material";
interface Notification {
  text: string;
  color: string;
  btnText: string;
}

function toSorted<T>(arr: T[], sortFn: (a: T, b: T) => number) {
  const array = [...arr];

  array.sort(sortFn);

  return array;
}

// Step 1: Define the context shape
interface NotificationContextProps {
  addNotification: (notif: Notification) => string;
  addNotifications: (notif: Notification[]) => string[];
  removeNotification: (uuid: string) => void;
  getAllNotifications: () => Notification[];
}

// Create the context with an initial undefined value
const NotificationContext = createContext<NotificationContextProps | undefined>(
  undefined
);

type NotifDict = {[uuid: string]: Notification & {order: number, uuid: string}};

function getColor(col: string) {
  const colors: {[key: string]: string} = {
    'ok': "#579d36",
    'error': "#A11E1E"
  }

  if(Object.keys(colors).includes(col)) {
    return colors[col];
  }

  return col;
}

// Step 2: Define the provider component
const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<NotifDict>({});
  const orderedNotifs = useMemo(() => toSorted(Object.values(notifications || {}), (a, b) => a.order - b.order), [notifications])

  const variants = {
    initial: { opacity: 0, x: 200 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 200 }
  };
  
  function addNotification(notif: Notification): string {
    const uuid = crypto.randomUUID();
    const addition: NotifDict = {};
    addition[uuid] = {...notif, order: Object.keys(notifications).length, uuid};
    setNotifications(notifs => Object.assign(addition, notifs));

    return uuid;
  }

  function addNotifications(notifs: Notification[]): string[] {
    const uuids: string[] = [];
    const addition: NotifDict = {};
    for(const notif of notifs) {
      const uuid = crypto.randomUUID();
      addition[uuid] = {...notif, order: Object.keys(notifications).length, uuid};

      uuids.push(uuid);
    }
    

    setNotifications(notifs => Object.assign(addition, notifs));

    return uuids;
  }

  function removeNotification(uuid: string): void {
    setNotifications(notifs => {
      const notifications = Object.assign({}, notifs);

      delete notifications[uuid];
      return notifications
    });
  }

  return (
    <NotificationContext.Provider
      value={{ addNotification, removeNotification, addNotifications, getAllNotifications: () => orderedNotifs }}
    >
      {children}
      <div className={classes.notificationContainter}>
        <AnimatePresence>
          {orderedNotifs.map((n) => (
            <motion.div
              initial="initial"
              animate="animate"
              exit="exit"
              variants={variants}
              transition={{ duration: 0.2 }}
              key={n.uuid + '_d'}
            >
              <Paper elevation={2} sx={{backgroundColor: getColor(n.color)}} className={classes.notification} key={n.uuid + '_p'}>
                <Typography component={'div'} sx={{fontSize: '16px', color: 'white', maxHeight: '500px', overflow: 'auto'}}>{n.text}</Typography>
                <div className={classes.buttonContainer} style={{color: 'white'}} onClick={() => removeNotification(n.uuid)}>
                  {n.btnText}
                </div>
              </Paper>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </NotificationContext.Provider>
  );
};

// Custom hook for easy access to context
const useNotification: () => NotificationContextProps = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error("useLogin must be used within a NotificationProvider");
  }
  return context;
};

export { NotificationProvider, useNotification };
